import {
  AbcReduction,
  BaseHorseReduction,
  PointsReduction,
  Reduction,
} from "../../types/bet";

const RANK_NAMES = "ABCDEFGH";

export const MAX_RANK_LENGTH = RANK_NAMES.length;

export const getAbcRankName = (rankIndex: number): string | undefined => {
  const name = RANK_NAMES.charAt(rankIndex);
  if (!name) return undefined;
  return name;
};

const getAbcReductionDefaultName = (amountOfRows: number): string =>
  RANK_NAMES.slice(0, amountOfRows);

export const getBaseHorseReductionName = (
  reduction?: BaseHorseReduction
): string => reduction?.name || "Utgång";

export const getAbcReductionName = (reduction?: AbcReduction): string =>
  reduction?.name ||
  `${getAbcReductionDefaultName(
    reduction?.abcVariants[0]?.abcRows.length || 3
  )}-utgång`;

export const getPointsReductionName = (reduction?: PointsReduction): string =>
  reduction?.name || "Poäng-utgång";

export const getFactorReductionName = (reduction?: Reduction): string =>
  reduction?.name || "Faktor-utgång";
