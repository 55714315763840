import { reportError } from "../sentry/logger";
import { theme } from "../styled";

const getGradient = (leftColor: string, rightColor: string) =>
  `linear-gradient(90deg, ${leftColor}, ${rightColor})`;

const { color } = theme;

export const gameColors: { [gameType: string]: [string, string] } = {
  V86: [color.purple.dark, color.purple.light],
  V75: [color.blue.default, color.blue.light],
  GS75: [color.teal.dark, color.teal.default],
  V65: [color.red.default, color.red.light],
  V64: [color.orange.dark, color.orange.light],
  V5: [color.blue.light, color.blue.lighter],
  V4: [color.green.default, color.green.light],
  V3: [color.blue.light, color.blue.lighter],
  DD: [color.blue.light, color.blue.lighter],
  LD: [color.blue.light, color.blue.lighter],
  TRIO: [color.blue.light, color.blue.lighter],
};

export const getGameColors = (gameType?: string): [string, string] => {
  if (gameType && gameType.toUpperCase() in gameColors)
    return gameColors[gameType.toUpperCase()];
  return [color.background.grey.darker, color.background.grey.dark];
};

export const getGameColor = (gameType?: string): string => {
  return getGameColors(gameType)[0];
};

export const getGameBackground = (gameType?: string): string => {
  if (!gameType) return color.background.loading;
  const [c1, c2] = getGameColors(gameType);
  return getGradient(c1, c2);
};

const getAmountOfToleratedErrors = (gameType?: string): number => {
  const gameDigits = gameType?.match(/\d/g)?.map(parseInt);

  // V5, V4, V3...
  if (gameDigits?.length === 1) return 0;

  // V75, GS75...
  if (gameDigits?.length === 2) {
    const [a, b] = gameDigits;
    return a - b;
  }

  // ???
  reportError(new Error(`Unknown gameType: ${gameType}`));
  return 0;
};

export const getNrOfBetsColor = (
  gameType?: string,
  amountOfErrors?: number
): string | undefined => {
  if (amountOfErrors == null) return undefined;
  if (amountOfErrors === 0) return color.green.darker;

  const amountOfToleratedErrors = getAmountOfToleratedErrors(gameType);
  if (amountOfErrors > amountOfToleratedErrors) return undefined;
  if (amountOfErrors === amountOfToleratedErrors)
    return color.text.grey.default;

  return color.red.dark;
};
