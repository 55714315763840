import { Remote } from "comlink";
import { SetState } from "zustand";
import { BetStore } from ".";
import fetcher from "../../libs/fetcher";
import isServer from "../../libs/is-server";
import { timeout } from "../../libs/timeout";
import { BetResponse } from "../../types/api";
import { Bet } from "../../types/bet";
import { isFetchError } from "../../types/error";
import { IDBBetSpoilerHandler } from "../../workers/idb-bet-spoiler-storer";
import { IDBBetHandler } from "../../workers/idb-bet-storer";

const fetchBet = async (
  id: string | string[],
  set: SetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSpoilerWorkerApi?: Remote<IDBBetSpoilerHandler>
): Promise<void> => {
  // Set loading state
  set({ loading: true, error: undefined, usingLocal: false });

  let getIdbBet:
    | ((id: IDBValidKey) => Promise<Bet | undefined | null>)
    | undefined;
  if (!isServer) {
    const idb = await import("../../libs/idb");
    getIdbBet = idb.getBet;
  }

  try {
    const { bet, editable } = await timeout(
      10000,
      fetcher<BetResponse>(`/api/bets/${id}`)
    );

    let showRaceResult = bet.rows.map(() => false);
    let showBetResult = false;

    const spoilerRes = await idbBetSpoilerWorkerApi?.getBetSpoiler(id);
    if (spoilerRes != null) {
      showRaceResult = spoilerRes.showRaceResult;
      showBetResult = spoilerRes.showBetResult;
    }

    // Fetch successful
    set({
      loading: false,
      bet,
      editable,
      showRaceResult,
      showBetResult,
    });

    // Store bet using workers
    if (idbBetWorkerApi && editable) idbBetWorkerApi.storeBet(id, bet);
  } catch (error) {
    if (getIdbBet) {
      getIdbBet(id)
        .then(async (bet) => {
          if (bet) {
            // Found local bet
            let showRaceResult = bet.rows.map(() => false);
            let showBetResult = false;

            const spoilerRes = await idbBetSpoilerWorkerApi?.getBetSpoiler(id);
            if (spoilerRes != null) {
              showRaceResult = spoilerRes.showRaceResult;
              showBetResult = spoilerRes.showBetResult;
            }

            set({
              loading: false,
              bet,
              editable: true,
              usingLocal: true,
              showRaceResult,
              showBetResult,
              error: isFetchError(error) ? error : undefined,
            });
          } else {
            set({
              loading: false,
              error: isFetchError(error) ? error : undefined,
              usingLocal: false,
            });
          }
        })
        .catch((error) => set({ loading: false, error, usingLocal: false }));
    }
  }
};

export default fetchBet;
