import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import isServer from "../../../libs/is-server";
import { replaceInList } from "../../../libs/list-utils/replace-in-list";
import { BaseHorseReduction, Bet } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import uploadBet from "../upload-bet";

export const updateBaseHorseReduction = async (
  id: string,
  reductionIndex: number,
  newBaseHorseReduction: Partial<BaseHorseReduction>,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  if (!bet) {
    return;
  }

  const { reductionRequirement } = bet;

  if (reductionRequirement.baseHorseReductions == null) {
    reductionRequirement.baseHorseReductions = [];
  }

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      baseHorseReductions: replaceInList<BaseHorseReduction>(
        reductionRequirement.baseHorseReductions,
        reductionIndex,
        {
          ...reductionRequirement.baseHorseReductions[reductionIndex],
          ...newBaseHorseReduction,
        }
      ),
    },
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (!isServer) {
    uploadBet(id, newBet, () => set({ uploading: false }), idbBetSyncWorkerApi);

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(id, newBet);
  }
};
