import { Remote } from "comlink";
import { putter } from "../../libs/fetcher";
import { Bet } from "../../types/bet";
import { IDBBetSyncHandler } from "../../workers/idb-bet-sync-handler";

const uploadBet = (
  id: string,
  bet: Bet,
  onSuccess: () => void,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): void => {
  putter<Bet>(`/api/bets/${id}`, {
    body: JSON.stringify({ bet }),
  })
    .then(() => {
      onSuccess();
      if (idbBetSyncWorkerApi) {
        idbBetSyncWorkerApi.removeBetIdFromBetsToUpload(id);
      }
    })
    .catch(() => {
      // TODO: Display some message or indication?
      if (idbBetSyncWorkerApi) {
        idbBetSyncWorkerApi.addBetIdToBetsToUpload(id);
      }
    });
};

export default uploadBet;
