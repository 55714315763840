import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import { MAX_RANK_LENGTH } from "../../../libs/bet-name/helpers";
import isServer from "../../../libs/is-server";
import { replaceInList } from "../../../libs/list-utils/replace-in-list";
import { AbcVariant, Bet } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import useAppStore from "../../app-store";
import uploadBet from "../upload-bet";

export const addAbcRow = async (
  variantIndex: number,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  const { currentBetId } = useAppStore.getState();

  if (!currentBetId || !bet) {
    return;
  }

  const { reductionRequirement } = bet;

  if (reductionRequirement.abcReduction == null) {
    throw new Error("No abcReduction when addAbcRow");
  }

  const { abcReduction } = reductionRequirement;

  const { abcRows } = abcReduction.abcVariants.slice()[variantIndex];

  if (abcRows.length >= MAX_RANK_LENGTH) return;

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      abcReduction: {
        abcVariants: replaceInList<AbcVariant>(
          reductionRequirement.abcReduction.abcVariants,
          variantIndex,
          {
            ...(reductionRequirement.abcReduction.abcVariants[variantIndex] ||
              []),
            ...{
              abcRows: [
                ...abcRows,
                {
                  legNumbers: bet.rows.map((row) => row.legNr),
                  minWinners: null,
                  maxWinners: null,
                },
              ],
            },
          }
        ),
      },
    },
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (!isServer) {
    uploadBet(
      currentBetId,
      newBet,
      () => set({ uploading: false }),
      idbBetSyncWorkerApi
    );

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(currentBetId, newBet);
  }
};
