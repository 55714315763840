import { Router } from "next/router";
import React, { useEffect } from "react";
import { page } from "../../libs/analytics";

const RouteChangeTracker: React.FC = () => {
  useEffect(() => {
    Router.events.on("routeChangeComplete", (url) => {
      page(url);
    });
  }, []);

  return null;
};

export default RouteChangeTracker;
