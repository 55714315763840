import { User } from "firebase/auth";
import create from "zustand";
import { ZustandCreate } from "./types";

type UserState = {
  user?: User;
  loading: boolean;
  displayGuide: boolean;
  guideIndex: number;
};

interface UserStore extends UserState, ZustandCreate {
  setUser: (user?: User) => void;
  setLoading: (loading: boolean) => void;
  setDisplayGuide: (displayGuide: boolean) => void;
  setGuideIndex: (guideIndex: number) => void;
}

const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  loading: true,
  displayGuide: false,
  guideIndex: 0,
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setDisplayGuide: (displayGuide) => set({ displayGuide }),
  setGuideIndex: (guideIndex) => set({ guideIndex }),
}));

export default useUserStore;
