import React, { useCallback, useMemo } from "react";
import shallow from "zustand/shallow";
import AppBar, { MenuItem } from "../../components/app-bar";
import { track } from "../../libs/analytics";
import {
  getAbcReductionName,
  getBaseHorseReductionName,
  getFactorReductionName,
  getPointsReductionName,
} from "../../libs/bet-name/helpers";
import { generateMenuItems } from "../../libs/generate-menu-items";
import useMediaQuery from "../../libs/hooks/use-media-query";
import { Breakpoint, Comp, mq } from "../../libs/media-master";
import { getGameBackground } from "../../libs/style/game-color";
import useAppStore, { ReductionRequirementView } from "../../state/app-store";
import useBetStore from "../../state/bet-store";
import useGameStore from "../../state/game-store";
import useTabsStore from "../../state/tabs-store";
import useUserStore from "../../state/user-store";
import { ReductionRequirement } from "../../types/bet";

interface Props {
  shouldUseTabs?: boolean;
  shouldUseBackButton?: boolean;
  shouldUseSettingsButton?: boolean;
}

const getTitleFromReduction = (
  reductionRequirementView: ReductionRequirementView,
  reductionRequirement?: ReductionRequirement,
  selectedReductionIndex?: number
) => {
  switch (reductionRequirementView) {
    case ReductionRequirementView.RANK:
      return "Ranking";

    case ReductionRequirementView.BASE_HORSE:
      if (selectedReductionIndex == null || reductionRequirement == null)
        return "Okänd utgång";
      return getBaseHorseReductionName(
        reductionRequirement.baseHorseReductions?.[selectedReductionIndex]
      );

    case ReductionRequirementView.ABC:
      if (reductionRequirement == null || !reductionRequirement.abcReduction)
        return "Okänd ABC-utgång";
      return getAbcReductionName(reductionRequirement.abcReduction);

    case ReductionRequirementView.POINTS:
      if (selectedReductionIndex == null || reductionRequirement == null)
        return "Okänd poäng-utgång";
      return getPointsReductionName(
        reductionRequirement.pointsReductions?.[selectedReductionIndex]
      );

    case ReductionRequirementView.FACTOR:
      if (reductionRequirement == null || !reductionRequirement.factorReduction)
        return "Okänd faktor-utgång";
      return getFactorReductionName(reductionRequirement.factorReduction);

    default:
      return "N/A";
  }
};

const AppBarContainer: React.FC<Props> = React.memo(
  ({
    shouldUseTabs = false,
    shouldUseBackButton = false,
    shouldUseSettingsButton = false,
  }) => {
    const isLargeScreen = useMediaQuery(
      mq([Comp.BIGGER_THAN_OR_EQUAL, Breakpoint.TABLET])
    );

    const useTabs = useMemo(
      () => !isLargeScreen && shouldUseTabs,
      [isLargeScreen, shouldUseTabs]
    );

    const [tabValue, setTabValue, resetTabValue] = useTabsStore(
      (state) => [state.tabValue, state.setTabValue, state.resetTabValue],
      shallow
    );

    const [displayGuide, guideIndex] = useUserStore(
      (state) => [state.displayGuide, state.guideIndex],
      shallow
    );

    const isHighlighted = useMemo(
      () => displayGuide && guideIndex === 2,
      [displayGuide, guideIndex]
    );

    const onTabsUnmount = useCallback(() => resetTabValue(), [resetTabValue]);

    const [amountOfRaces, gameType] = useGameStore(
      (state) => [state.amountOfRaces, state.gameType],
      shallow
    );

    const [
      reductionRequirementView,
      selectedReductionIndex,
      setReductionRequirementView,
      title,
    ] = useAppStore(
      (state) => [
        state.reductionRequirementView,
        state.selectedReductionIndex,
        state.setReductionRequirementView,
        state.appBarTitle,
      ],
      shallow
    );

    const [
      addBaseHorseReduction,
      addAbcReduction,
      addPointsReduction,
      addFactorReduction,
      editable,
      reductionRequirement,
    ] = useBetStore(
      (state) => [
        state.addBaseHorseReduction,
        state.addAbcReduction,
        state.addPointsReduction,
        state.addFactorReduction,
        state.editable,
        state.bet?.reductionRequirement,
      ],
      shallow
    );

    const onTabChange = useCallback(
      (e: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        e.preventDefault();
        setTabValue(newValue);
        track("App Bar Tab Changed", {
          tab_index: newValue,
          game_type: gameType,
          amount_of_tabs: amountOfRaces,
          editable,
        });
      },
      [setTabValue, gameType, amountOfRaces, editable]
    );

    const selectRankCallback = useCallback(() => {
      setReductionRequirementView(ReductionRequirementView.RANK);
      track("Rank Reduction View Selected", {
        game_type: gameType,
        editable,
      });
    }, [gameType, editable]);

    const selectBaseHorseCallback = useCallback(
      (selectedReductionIndex: number) => {
        setReductionRequirementView(
          ReductionRequirementView.BASE_HORSE,
          selectedReductionIndex
        );
        track("Base Horse Reduction View Selected", {
          game_type: gameType,
          selected_reduction_index: selectedReductionIndex,
          editable,
        });
      },
      [gameType, editable]
    );

    const selectAbcCallback = useCallback(() => {
      setReductionRequirementView(ReductionRequirementView.ABC);
      track("ABC Reduction View Selected", {
        game_type: gameType,
        editable,
      });
    }, [gameType, editable]);

    const selectPointsCallback = useCallback(
      (selectedReductionIndex: number) => {
        setReductionRequirementView(
          ReductionRequirementView.POINTS,
          selectedReductionIndex
        );
        track("Points Reduction View Selected", {
          game_type: gameType,
          selected_reduction_index: selectedReductionIndex,
          editable,
        });
      },
      [gameType, editable]
    );

    const selectFactorCallback = useCallback(() => {
      setReductionRequirementView(ReductionRequirementView.FACTOR);
      track("Factor Reduction View Selected", {
        game_type: gameType,
        editable,
      });
    }, [gameType, editable]);

    const selectNewBaseHorseCallback = useCallback(() => {
      if (!editable) return;
      addBaseHorseReduction(true);
      track("New Base Horse Reduction Clicked", {
        game_type: gameType,
      });
    }, [addBaseHorseReduction, editable]);

    const selectNewAbcCallback = useCallback(() => {
      if (!editable) return;
      addAbcReduction(true);
      track("New ABC Reduction Clicked", {
        game_type: gameType,
      });
    }, [addAbcReduction, editable]);

    const selectNewPointsCallback = useCallback(() => {
      if (!editable) return;
      addPointsReduction(true);
      track("New Points Reduction Clicked", {
        game_type: gameType,
      });
    }, [addPointsReduction, editable]);

    const selectNewFactorCallback = useCallback(() => {
      if (!editable) return;
      addFactorReduction(true);
      track("New Factor Reduction Clicked", {
        game_type: gameType,
      });
    }, [addFactorReduction, editable]);

    const menuItems: MenuItem[] = useMemo(
      () =>
        reductionRequirement
          ? generateMenuItems(
              reductionRequirement,
              selectRankCallback,
              selectBaseHorseCallback,
              selectAbcCallback,
              selectPointsCallback,
              selectFactorCallback,
              selectNewBaseHorseCallback,
              selectNewAbcCallback,
              selectNewPointsCallback,
              selectNewFactorCallback,
              editable
            )
          : [],
      [
        reductionRequirement,
        selectRankCallback,
        selectBaseHorseCallback,
        selectAbcCallback,
        JSON.stringify(reductionRequirement),
        useTabs,
        editable,
      ]
    );

    const useTitleAsMenu = !!menuItems && shouldUseTabs;

    return (
      <AppBar
        title={
          useTitleAsMenu
            ? getTitleFromReduction(
                reductionRequirementView,
                reductionRequirement,
                selectedReductionIndex
              )
            : title
        }
        useTabs={useTabs}
        useBackButton={shouldUseBackButton}
        useSettingsButton={shouldUseSettingsButton}
        tabsProps={{
          amountOfRaces: amountOfRaces || 0,
          tabValue,
          onTabChange,
          onUnmount: onTabsUnmount,
          indicatorBackground: getGameBackground(gameType),
        }}
        useTitleAsMenu={useTitleAsMenu}
        menuItems={menuItems}
        isHighlighted={isHighlighted}
      />
    );
  }
);

export default AppBarContainer;
