export enum Breakpoint {
  SMALL_PHONE = 320,
  PHONE = 375,
  TABLET = 768,
  DESKTOP = 1024,
  LARGE_DESKTOP = 1440,
}

export enum Comp {
  BIGGER_THAN,
  BIGGER_THAN_OR_EQUAL,
  SMALLER_THAN,
  SMALLER_THAN_OR_EQUAL,
}

export enum LogOp {
  AND,
  OR,
}

export enum Type {
  PRINT = "print",
  SCREEN = "screen",
  SPEECH = "speech",
}

interface ExtraMediaConfig {
  // TODO: Implement functionality if needed?
  type?: Type;
}

type BasicQuery = [Comp, Breakpoint, ExtraMediaConfig?];

type ExtraQuery = [LogOp, BasicQuery];

function parseComp(comp: Comp) {
  switch (comp) {
    case Comp.BIGGER_THAN_OR_EQUAL:
      return `min-width`;
    case Comp.SMALLER_THAN_OR_EQUAL:
      return `max-width`;
    default:
      throw new Error(`Can't parse comparision operator ${comp}`);
  }
}

function parseLogOp(logOp: LogOp) {
  switch (logOp) {
    case LogOp.AND:
      return " and";
    case LogOp.OR:
      return ",";
    default:
      throw new Error(`Unknown LogOp: ${logOp}`);
  }
}

function parseQueries(queries: ExtraQuery[]) {
  return queries.map((query) => parseExtraQuery(query)).join("");
}

function parseExtraQuery(query: ExtraQuery) {
  const [logOp, basicQuery] = query;
  return `${parseLogOp(logOp)} ${parseQuery(basicQuery)}`;
}

function parseQuery(query: BasicQuery): string {
  const [comparision, breakpoint, config] = query;

  if (comparision === Comp.BIGGER_THAN) {
    return parseQuery([Comp.BIGGER_THAN_OR_EQUAL, breakpoint + 1, config]);
  } else if (comparision === Comp.SMALLER_THAN) {
    return parseQuery([Comp.SMALLER_THAN_OR_EQUAL, breakpoint - 1, config]);
  }

  return `(${parseComp(comparision)}: ${breakpoint}px)`;
}

export const mq = (query: BasicQuery, ...queries: ExtraQuery[]): string =>
  `${parseQuery(query)}${parseQueries(queries)}`.trim();

export default function media(
  query: BasicQuery,
  ...queries: ExtraQuery[]
): string {
  return `@media ${parseQuery(query)}${parseQueries(queries)}`.trim();
}
