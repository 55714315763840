import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import isServer from "../../../libs/is-server";
import { removeFromList } from "../../../libs/list-utils/remove-from-list";
import { reportError } from "../../../libs/sentry/logger";
import { Bet } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import useAppStore, { ReductionRequirementView } from "../../app-store";
import uploadBet from "../upload-bet";

export const removePointsReduction = async (
  reductionIndex: number,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  const {
    currentBetId,
    selectedReductionIndex,
    reductionRequirementView,
  } = useAppStore.getState();

  if (!currentBetId || !bet) {
    return;
  }

  const { reductionRequirement } = bet;

  if ((reductionRequirement.pointsReductions?.length || 0) <= reductionIndex) {
    reportError(new Error("Bet reductions are less then given index"), {
      reductionIndex,
      reductionLength: reductionRequirement.pointsReductions?.length,
    });
    return;
  }

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      pointsReductions: removeFromList(
        reductionRequirement.pointsReductions || [],
        reductionIndex
      ),
    },
    rows: bet.rows.map((row) => ({
      ...row,
      pointsReductions: removeFromList(
        row.pointsReductions || [],
        reductionIndex
      ),
    })),
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (
    reductionRequirementView === ReductionRequirementView.POINTS &&
    selectedReductionIndex === reductionIndex
  ) {
    useAppStore
      .getState()
      .setReductionRequirementView(ReductionRequirementView.RANK);
  }

  if (!isServer) {
    uploadBet(
      currentBetId,
      newBet,
      () => set({ uploading: false }),
      idbBetSyncWorkerApi
    );

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(currentBetId, newBet);
  }
};
