import { css, Global, ThemeProvider } from "@emotion/react";
import { StylesProvider } from "@material-ui/core/styles";
// import whyDidYouRender from "@welldone-software/why-did-you-render";
import App from "next/app";
import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { theme } from "../libs/styled";

// if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
//   whyDidYouRender(React);
// }

export const cssReset = css`
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy ExtraBold"), local("Gilroy-ExtraBold"),
      url("/fonts/Gilroy-ExtraBold.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
    font-display: optional;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    height: 100%;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  strong,
  b {
    font-weight: bolder;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  body {
    margin: 0;
    min-height: 100%;
    color: #000;
    font-family: ${theme.fontFamily.system};
    touch-action: manipulation;
    background-color: #fff;
    &::backdrop {
      background-color: #fff;
    }
  }
  :focus {
    outline: none;
  }
  ::-moz-selection {
    color: ${theme.color.text.light};
    background: ${theme.color.blue.default};
  }
  ::selection {
    color: ${theme.color.text.light};
    background: ${theme.color.blue.default};
  }
`;

export default class MyApp extends App {
  componentDidMount(): void {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }

    if (!window.Proxy) {
      require("proxy-polyfill");
    }
  }

  render(): JSX.Element {
    // @ts-ignore
    const { Component, pageProps, err, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <SEO>
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,viewport-fit=cover"
          />
        </SEO>
        <StylesProvider injectFirst>
          {/* Custom CSSBaseline */}
          <Global styles={cssReset} />
          <Layout>
            <Component {...pageProps} {...rest} err={err} />
          </Layout>
        </StylesProvider>
      </ThemeProvider>
    );
  }
}
