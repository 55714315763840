import * as Sentry from "@sentry/nextjs";

export const reportError = (
  error: any,
  extras?: Record<string, unknown>
): void => {
  if (process.env.NODE_ENV === "production") {
    Sentry.captureException(error, {
      extra: {
        ...extras,
      },
    });
  } else {
    console.error(error, extras);
  }
};

export const reportWarning = (warning: string): void => {
  if (process.env.NODE_ENV === "production") {
    Sentry.captureMessage(warning, Sentry.Severity.Warning);
  } else {
    console.warn(warning);
  }
};
