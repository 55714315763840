import styled from "@emotion/styled";

const Divider = styled.hr`
  margin-top: 0;
  margin-bottom: 1rem;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.color.background.grey.light};
`;

export default Divider;
