import { FetchError } from "../../types/error";

const getHeaders = (initHeaders?: HeadersInit, token?: string): HeadersInit =>
  new Headers({
    "Content-Type": "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...initHeaders,
  });

export default async function baseFetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
  method?: string,
  token?: string
): Promise<JSON> {
  const res = await fetch(input, {
    method: method || "GET",
    headers: getHeaders(init?.headers, token),
    ...init,
  });
  if (!res.ok) throw new FetchError(res.status, res.statusText);

  const { data, ok, error } = await res.json();
  if (!ok) throw new FetchError(res.status, error);

  return data;
}

export const baseRequest = async (
  input: RequestInfo,
  method: string,
  init?: RequestInit,
  token?: string
): Promise<Response> =>
  fetch(input, {
    method,
    headers: getHeaders(init?.headers, token),
    ...init,
  });
