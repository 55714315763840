import create from "zustand";
import { ZustandCreate } from "./types";

type TabsState = {
  tabValue: number;
};

interface TabsStore extends TabsState, ZustandCreate {
  setTabValue: (tabValue: number) => void;
  resetTabValue: () => void;
}

const useTabsStore = create<TabsStore>((set) => ({
  tabValue: 0,
  setTabValue: (tabValue) => set({ tabValue }),
  resetTabValue: () => set({ tabValue: 0 }),
}));

export default useTabsStore;
