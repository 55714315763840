import { releaseProxy, wrap } from "comlink";
import { useEffect, useMemo } from "react";
import useBetStore from "../../state/bet-store";
import { Bet } from "../../types/bet";
import { putter } from "../fetcher";
import areWorkersSupported from "./../caniuse-web-workers";

export function useBetUploader(isOnline: boolean): void {
  const workerSupport = areWorkersSupported();

  const setUploading = useBetStore((state) => state.setUploading);

  if (!workerSupport) {
    return;
  }

  const { workerApi } = useWorker();

  useEffect(() => {
    // Start off clean to prevent user from occidentally overriding system
    workerApi.clearChangedBets();
  }, []);

  useEffect(() => {
    if (isOnline) {
      workerApi
        .getChangedBets()
        .then((bets) =>
          Promise.all(
            bets.map((bet) => {
              if (bet) {
                return putter<Bet>(`/api/bets/${bet.id}`, {
                  body: JSON.stringify({ bet }),
                });
              }
            })
          )
        )
        .then((responses) => {
          if (responses.every((response) => response != null)) {
            return workerApi.clearChangedBets();
          }
        })
        .finally(() => setUploading(false));
    }
  }, [workerApi, isOnline, workerSupport]);
}
function useWorker() {
  // memoise a worker so it can be reused; create one worker up front
  // and then reuse it subsequently; no creating new workers each time
  const workerApiAndCleanup = useMemo(() => makeWorkerApiAndCleanup(), []);

  useEffect(() => {
    const { cleanup } = workerApiAndCleanup;

    // cleanup our worker when we're done with it
    return () => {
      cleanup();
    };
  }, [workerApiAndCleanup]);

  return workerApiAndCleanup;
}

/**
 * Creates a worker, a cleanup function and returns it
 */
function makeWorkerApiAndCleanup() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker(
    new URL("./../../workers/get-changed-bets.ts", import.meta.url)
  );
  const workerApi =
    wrap<import("../../workers/get-changed-bets").GetChangedBets>(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  const workerApiAndCleanup = { workerApi, cleanup };

  return workerApiAndCleanup;
}
