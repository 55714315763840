import { getIdTokenKeyFromFirebase } from "../auth/id-token";
import baseFetcher from "./base-fetcher";

export default async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  return baseFetcher(input, init, "GET", await getIdTokenKeyFromFirebase());
}

export const poster = async <JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> =>
  baseFetcher(input, init, "POST", await getIdTokenKeyFromFirebase());

export const posterUsingCustomToken = async <JSON = any>(
  input: RequestInfo,
  token: string,
  init?: RequestInit
): Promise<JSON> => baseFetcher(input, init, "POST", token);

export const putter = async <JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> =>
  baseFetcher(input, init, "PUT", await getIdTokenKeyFromFirebase());

export const deleter = async <JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> =>
  baseFetcher(input, init, "DELETE", await getIdTokenKeyFromFirebase());
