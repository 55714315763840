import { MenuItem } from "../components/app-bar";
import {
  BaseHorseReduction,
  PointsReduction,
  ReductionRequirement,
} from "../types/bet";
import {
  getAbcReductionName,
  getBaseHorseReductionName,
  getFactorReductionName,
  getPointsReductionName,
} from "./bet-name/helpers";

const generateBaseHorseMenuItems = (
  baseHorseReductions: BaseHorseReduction[],
  onSelectBaseHorse: (selectedReductionIndex: number) => void
): MenuItem[] =>
  baseHorseReductions.map((baseHorseReduction, index) => ({
    name: getBaseHorseReductionName(baseHorseReduction),
    onSelect: () => onSelectBaseHorse(index),
  }));

const generatePointsMenuItems = (
  pointsReductions: PointsReduction[],
  onSelectPoints: (selectedReductionIndex: number) => void
): MenuItem[] =>
  pointsReductions.map((pointsReduction, index) => ({
    name: getPointsReductionName(pointsReduction),
    onSelect: () => onSelectPoints(index),
  }));

export const generateMenuItems = (
  reductionRequirement: ReductionRequirement,
  onSelectRank: () => void,
  onSelectBaseHorse: (selectedReductionIndex: number) => void,
  onSelectAbc: () => void,
  onSelectPoints: (selectedReductionIndex: number) => void,
  onSelectFactor: () => void,
  onAddBaseHorse: () => void,
  onAddAbc: () => void,
  onAddPoints: () => void,
  onAddFactor: () => void,
  editable?: boolean
): MenuItem[] => {
  if (!reductionRequirement) return [];

  const {
    baseHorseReductions,
    abcReduction,
    pointsReductions,
    factorReduction,
  } = reductionRequirement;
  return [
    { name: "Ranking", onSelect: onSelectRank },
    ...(baseHorseReductions
      ? generateBaseHorseMenuItems(baseHorseReductions, onSelectBaseHorse)
      : []),
    ...(abcReduction
      ? [
          {
            name: getAbcReductionName(abcReduction),
            onSelect: () => onSelectAbc(),
          },
        ]
      : []),
    ...(pointsReductions
      ? generatePointsMenuItems(pointsReductions, onSelectPoints)
      : []),
    ...(factorReduction
      ? [
          {
            name: getFactorReductionName(factorReduction),
            onSelect: onSelectFactor,
          },
        ]
      : []),
    { name: "Divider", onSelect: () => {}, isDivider: true },
    { name: "Ny utgång", onSelect: onAddBaseHorse, disabled: !editable },
    ...(!abcReduction
      ? [
          {
            name: `Ny ${getAbcReductionName()}`,
            onSelect: onAddAbc,
            disabled: !editable,
          },
        ]
      : []),
    {
      name: `Ny ${getPointsReductionName().toLowerCase()}`,
      onSelect: onAddPoints,
      disabled: !editable,
    },
    ...(!factorReduction
      ? [
          {
            name: `Ny ${getFactorReductionName().toLowerCase()}`,
            onSelect: onAddFactor,
            disabled: !editable,
          },
        ]
      : []),
  ];
};
