import { keyframes } from "@emotion/react";

const SYSTEM_FONT_RULE = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif`;

export const calcSkeletonHeight = (fontSize: string): string =>
  `calc(${fontSize} * 1.7)`;

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
  100% {
    opacity: 1;
  }
`;

export interface Color {
  mint: Mint;
  purple: GeneralColor;
  teal: Teal;
  red: Red;
  blue: Blue;
  orange: GeneralColor;
  green: Green;
  text: Text;
  background: Background;
  white: string;
  black: string;
}

interface Background {
  white: string;
  black: string;
  loading: string;
  grey: Grey;
}

interface Grey {
  lighter: string;
  light: string;
  default: string;
  dark: string;
  darker: string;
  darkest: string;
}

interface Blue {
  dark: string;
  default: string;
  light: string;
  lighter: string;
}

interface Red {
  dark: string;
  default: string;
  light: string;
  lighter: string;
}

interface Green {
  darker: string;
  dark: string;
  default: string;
  light: string;
}

interface Mint {
  default: string;
}

interface Teal {
  dark: string;
  default: string;
}

interface Text {
  light: string;
  dark: string;
  grey: GeneralColor;
}

interface GeneralColor {
  light: string;
  default: string;
  dark: string;
}

const color: Color = {
  mint: {
    default: "#69d9db",
  },
  purple: {
    light: "#BB66CC",
    default: "#9900ff",
    dark: "#7c23ff",
  },
  teal: {
    dark: "#008D7B",
    default: "#22AA99",
  },
  red: {
    dark: "#d21021",
    default: "#F22E26",
    light: "#E56969",
    lighter: "#FFC9CE",
  },
  blue: {
    dark: "#571eff",
    default: "#0055ff",
    light: "#0099ff",
    lighter: "#00ddff",
  },
  orange: {
    light: "#FFAA22",
    default: "#fc9534",
    dark: "#FF7744",
  },
  green: {
    darker: "#2d7d2d",
    dark: "#43a042",
    default: "#66BB66",
    light: "#99CC66",
  },
  text: {
    light: "#ffffff",
    dark: "#000000",
    grey: {
      light: "#c0c0c0",
      default: "#757575",
      dark: "#212121",
    },
  },
  background: {
    white: "#ffffff",
    black: "#000000",
    loading: "#ebebeb",
    grey: {
      lighter: "#f0f0f0",
      light: "#c0c0c0",
      default: "#757575",
      dark: "#484848",
      darker: "#262626",
      darkest: "#0b0b0b",
    },
  },
  white: "#ffffff",
  black: "#000000",
};

export interface Theme {
  fontFamily: {
    system: string;
    header: string;
  };
  fontWeigth: {
    light: number;
    header: number;
  };
  fontSize: {
    xsm: string;
    sm: string;
    base: string;
    lg: string;
    xl: string;
    xxl: string;
  };
  letterSpacing: {
    header: string;
    none: number;
  };
  color: Color;
  gradient: {
    blue: string;
    lightBlue: string;
    blueToPurple: string;
  };
  keyframe: {
    pulse: any;
  };
  boxShadow: {
    card: string;
    cardRaised: string;
  };
  borderRadius: {
    card: string;
  };
  easing: {
    default: string;
  };
  duration: {
    default: string;
    quick: string;
  };
}

export const theme: Theme = {
  fontFamily: {
    system: SYSTEM_FONT_RULE,
    header: `Gilroy, ${SYSTEM_FONT_RULE}`,
  },
  fontWeigth: {
    light: 300,
    header: 800,
  },
  fontSize: {
    xsm: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xxl: "2.125rem",
  },
  letterSpacing: {
    header: "-0.03em",
    none: 0,
  },
  color,
  gradient: {
    blue: `linear-gradient(90deg, ${color.blue.default}, ${color.blue.light})`,
    lightBlue: `linear-gradient(90deg, ${color.blue.light}, ${color.blue.lighter})`,
    blueToPurple: `linear-gradient(-90deg, ${color.purple.default}, ${color.blue.dark}, ${color.blue.light})`,
  },
  keyframe: {
    pulse,
  },
  boxShadow: {
    card: "rgba(0, 0, 0, 0.12) 0 0.25rem 0.5rem 0",
    cardRaised: "rgba(0, 0, 0, 0.12) 0 1.5rem 3rem",
  },
  borderRadius: {
    card: "1rem",
  },
  easing: {
    default: "cubic-bezier(0.4, 0, 0.2, 1)",
  },
  duration: {
    default: "0.3s",
    quick: "150ms",
  },
};
