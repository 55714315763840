import { Remote, wrap } from "comlink";
import create from "zustand";
import caniuseWebWorkers from "../../libs/caniuse-web-workers";
import { Bet, BetRow } from "../../types/bet";
import { FetchError } from "../../types/error";
import { IDBBetSpoilerHandler } from "../../workers/idb-bet-spoiler-storer";
import { IDBBetHandler } from "../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../workers/idb-bet-sync-handler";
import { ZustandCreate } from "../types";
import {
  AbcReductionStore,
  addAbcReduction,
  addAbcRow,
  removeAbcReduction,
  removeAbcRow,
  updateAbcReduction,
  updateAbcRow,
} from "./abc-reduction";
import {
  addBaseHorseReduction,
  BaseHorseReductionStore,
  removeBaseHorseReduction,
  updateBaseHorseReduction,
} from "./base-horse-reduction";
import {
  addFactorReduction,
  FactorReductionStore,
  removeFactorReduction,
  updateFactorReduction,
} from "./factor-reduction";
import fetchBet from "./fetch-bet";
import {
  addPointsReduction,
  removePointsReduction,
  updatePointsReduction,
} from "./points-reduction";
import { PointsReductionStore } from "./points-reduction/types";
import updateBet from "./update-bet";
import updateBetName from "./update-bet-name";
import updateBetRow from "./update-bet-row";

let idbBetWorkerApi: Remote<IDBBetHandler>;
let idbBetSyncWorkerApi: Remote<IDBBetSyncHandler>;
let idbBetSpoilerWorkerApi: Remote<IDBBetSpoilerHandler>;

if (caniuseWebWorkers()) {
  idbBetWorkerApi = wrap<IDBBetHandler>(
    new Worker(new URL("./../../workers/idb-bet-storer.ts", import.meta.url))
  );
  idbBetSyncWorkerApi = wrap<IDBBetSyncHandler>(
    new Worker(
      new URL("./../../workers/idb-bet-sync-handler.ts", import.meta.url)
    )
  );
  idbBetSpoilerWorkerApi = wrap<IDBBetSpoilerHandler>(
    new Worker(
      new URL("./../../workers/idb-bet-spoiler-storer", import.meta.url)
    )
  );
}

type BetState = {
  bet?: Bet;
  loading: boolean;
  editable?: boolean;
  error?: FetchError;
  uploading: boolean;
  usingLocal: boolean;
  showRaceResult: boolean[];
  showBetResult: boolean;
};

export interface BetStore
  extends BetState,
    ZustandCreate,
    BaseHorseReductionStore,
    AbcReductionStore,
    PointsReductionStore,
    FactorReductionStore {
  resetBet: () => void;
  resetBetError: () => void;
  setUploading: (uploading: boolean) => void;
  setShowRaceResult: (showRaceResult: boolean[]) => void;
  setShowBetResult: (showBetResult: boolean) => void;
  fetchBet: (id: string | string[]) => Promise<void>;
  updateBetName: (id: string, name: string) => Promise<void>;
  updateBetRow: (id: string, rowIndex: number, row: BetRow) => Promise<void>;
  updateBet: (id: string, betPart: Partial<Bet>) => Promise<void>;
}

const useBetStore = create<BetStore>((set, get) => ({
  bet: undefined,
  loading: false,
  error: undefined,
  uploading: false,
  usingLocal: false,
  showRaceResult: [],
  showBetResult: false,
  resetBet: () => set({ bet: undefined }),
  resetBetError: () => set({ error: undefined, usingLocal: false }),
  setUploading: (uploading) => set({ uploading }),
  setShowRaceResult: (showRaceResult) => set({ showRaceResult }),
  setShowBetResult: (showBetResult) => set({ showBetResult }),
  fetchBet: (id) => fetchBet(id, set, idbBetWorkerApi, idbBetSpoilerWorkerApi),
  updateBetName: (id, name) =>
    updateBetName(id, name, set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
  updateBet: (id, betPart) =>
    updateBet(id, betPart, set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
  updateBetRow: (id, rowIndex, row) =>
    updateBetRow(
      id,
      rowIndex,
      row,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  updateBaseHorseReduction: (id, reductionindex, newBaseHorseReduction) =>
    updateBaseHorseReduction(
      id,
      reductionindex,
      newBaseHorseReduction,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  addBaseHorseReduction: (shouldUpdateReductionView) =>
    addBaseHorseReduction(
      shouldUpdateReductionView || false,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  updateAbcReduction: (id, newAbcReduction) =>
    updateAbcReduction(
      id,
      newAbcReduction,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  updateAbcRow: (id, abcVariantIndex, abcRowIndex, newAbcRow) =>
    updateAbcRow(
      id,
      abcVariantIndex,
      abcRowIndex,
      newAbcRow,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  addAbcRow: (variantIndex) =>
    addAbcRow(variantIndex, set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
  removeAbcRow: (variantIndex) =>
    removeAbcRow(variantIndex, set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
  removeBaseHorseReduction: (reductionIndex) =>
    removeBaseHorseReduction(
      reductionIndex,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  addAbcReduction: (shouldUpdateReductionView) =>
    addAbcReduction(
      shouldUpdateReductionView || false,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  removeAbcReduction: () =>
    removeAbcReduction(set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
  updatePointsReduction: (id, reductionindex, newPointsReduction) =>
    updatePointsReduction(
      id,
      reductionindex,
      newPointsReduction,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  addPointsReduction: (shouldUpdateReductionView) =>
    addPointsReduction(
      shouldUpdateReductionView || false,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  removePointsReduction: (reductionIndex) =>
    removePointsReduction(
      reductionIndex,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  updateFactorReduction: (id, newFactorReduction) =>
    updateFactorReduction(
      id,
      newFactorReduction,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  addFactorReduction: (shouldUpdateReductionView) =>
    addFactorReduction(
      shouldUpdateReductionView || false,
      set,
      get,
      idbBetWorkerApi,
      idbBetSyncWorkerApi
    ),
  removeFactorReduction: () =>
    removeFactorReduction(set, get, idbBetWorkerApi, idbBetSyncWorkerApi),
}));

export default useBetStore;
