import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from ".";
import isServer from "../../libs/is-server";
import { replaceInList } from "../../libs/list-utils/replace-in-list";
import { Bet, BetRow } from "../../types/bet";
import { IDBBetHandler } from "../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../workers/idb-bet-sync-handler";
import uploadBet from "./upload-bet";

const updateBetRow = async (
  id: string,
  rowIndex: number,
  row: BetRow,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  if (!bet) return;

  const newBet: Bet = {
    ...bet,
    rows: replaceInList<BetRow>(bet.rows, rowIndex, row),
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (!isServer) {
    uploadBet(id, newBet, () => set({ uploading: false }), idbBetSyncWorkerApi);

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(id, newBet);
  }
};

export default updateBetRow;
