import styled from "@emotion/styled";
import MuiAppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useCallback } from "react";
import shallow from "zustand/shallow";
import { track } from "../../libs/analytics";
import isServer from "../../libs/is-server";
import useScrollTrigger from "../../libs/use-scroll-trigger";
import useAppStore from "../../state/app-store";
import Divider from "../divider/basic";
import MuiLink from "../mui-link";
import { ToolbarTitle } from "../typography";
import AppBarTabs, { AppBarTabsProps } from "./tabs";

interface Props {
  title?: string;
  useTabs?: boolean;
  useBackButton?: boolean;
  useTitleAsMenu?: boolean;
  useSettingsButton?: boolean;
  menuItems?: MenuItem[];
  tabsProps?: AppBarTabsProps;
  isHighlighted?: boolean;
}

export interface MenuItem {
  name: string;
  onSelect: () => void;
  isDivider?: boolean;
  disabled?: boolean;
}

interface HighlightedProps {
  isHighlighted?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "isHighlighted",
})<HighlightedProps>`
  background: #fff;
  color: #000;
  z-index: ${(props) => (props.isHighlighted ? 1400 : 99)};
`;

const RightIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  margin: 0 0.75rem;
`;

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    width: 60%;
    max-width: 25rem;
  }
`;

const AppBarButton = styled(Button)`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeigth.header};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.lg};
  text-align: center;
  position: absolute;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
  right: 0;
  width: 60%;
  max-width: 25rem;
  text-transform: none;
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: ${(props) => props.theme.fontFamily.system};
  font-size: ${(props) => props.theme.fontSize.base};
`;

const AppBar: React.FC<Props> = React.memo(
  ({
    title = "Reducera",
    useTabs = false,
    useBackButton = false,
    useTitleAsMenu = false,
    useSettingsButton = false,
    menuItems,
    tabsProps,
    ...props
  }) => {
    const elevationTrigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const setSettingsIsMounted = useAppStore(
      (state) => state.setSettingsIsMounted,
      shallow
    );

    const handleClickSettings = useCallback(() => {
      if (window) window.location.hash = "#settings";
      setSettingsIsMounted(true);
      track("App Bar Settings Clicked");
    }, [setSettingsIsMounted]);

    const handleClickMenu = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        track("App Bar Menu Clicked");
        setAnchorEl(event.currentTarget);
      },
      [setAnchorEl]
    );

    const handleMenuItemClick = useCallback(
      (onSelect: () => void, name: string, index: number) => {
        track("App Bar Menu Item Clicked", {
          name,
          index,
        });
        if (onSelect) onSelect();
        setAnchorEl(null);
      },
      [setAnchorEl]
    );

    const handleMenuClose = useCallback(() => {
      setAnchorEl(null);
    }, [setAnchorEl]);

    const handleBackClick = useCallback(() => {
      track("App Bar Back Button Clicked");
    }, []);

    return (
      <StyledAppBar
        elevation={elevationTrigger ? 4 : 0}
        position="sticky"
        {...props}
      >
        <Toolbar>
          {useBackButton && (
            <IconButton
              component={MuiLink}
              href="/"
              edge="start"
              onClick={handleBackClick}
              color="inherit"
              style={{ color: "inherit", marginLeft: "-0.75rem" }}
              aria-label="back"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {useTitleAsMenu && menuItems ? (
            <>
              <AppBarButton
                id="app-bar-menu-button"
                onClick={handleClickMenu}
                aria-haspopup="true"
                endIcon={<ExpandMoreIcon />}
              >
                {title.toLowerCase()}
              </AppBarButton>
              <StyledMenu
                id="lock-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {menuItems.map(
                  ({ name, onSelect, isDivider, disabled }, index) =>
                    isDivider ? (
                      <Divider
                        style={{ margin: 0 }}
                        key={`app-bar-menu-item-${index}`}
                      />
                    ) : (
                      <StyledMenuItem
                        key={`app-bar-menu-item-${index}`}
                        onClick={() =>
                          handleMenuItemClick(onSelect, name, index)
                        }
                        disabled={disabled}
                      >
                        {name}
                      </StyledMenuItem>
                    )
                )}
              </StyledMenu>
            </>
          ) : (
            <ToolbarTitle>{isServer ? "" : title.toLowerCase()}</ToolbarTitle>
          )}
          {useSettingsButton && (
            <RightIconButton
              color="inherit"
              aria-label="settings"
              id="app-bar-settings-button"
              onClick={handleClickSettings}
            >
              <SettingsIcon />
            </RightIconButton>
          )}
        </Toolbar>
        {useTabs && tabsProps && <AppBarTabs {...tabsProps} />}
      </StyledAppBar>
    );
  }
);

export default AppBar;
