export default {
  apiKey: "AIzaSyAXtZSLUa8i22mLMt4OeeFtohZIzOoaJ5A",
  authDomain: "reducera-nu.firebaseapp.com",
  databaseURL: "https://reducera-nu.firebaseio.com",
  projectId: "reducera-nu",
  storageBucket: "reducera-nu.appspot.com",
  messagingSenderId: "793312491508",
  appId: "1:793312491508:web:ca96c48e439037a006aba1",
  measurementId: "G-4KK38GHG3M",
};
