import styled from "@emotion/styled";
import { RectSkeleton } from "../skeleton";

export const H1 = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeigth.header};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.xxl};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BaseSystemFont = styled.p`
  font-family: ${(props) => props.theme.fontFamily.system};
  font-size: ${(props) => props.theme.fontSize.base};
  margin: 0;
`;

export const Paragraph = styled.p`
  font-family: ${(props) => props.theme.fontFamily.system};
  font-size: ${(props) => props.theme.fontSize.lg};
  margin: 1rem 0;
  line-height: 1.5rem;
`;

interface LoadingTextProps {
  width: number;
  totalHeight: number;
}

const LoadingContainer = styled.div<LoadingTextProps>`
  height: ${(props) => props.totalHeight}px;
  width: ${(props) => props.width}px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const LoadingH1Skeleton = styled(RectSkeleton)`
  height: ${(props) => props.theme.fontSize.xxl};
  width: 100%;
  border-radius: 0.5rem;
`;

export const LoadingH1: React.FC<LoadingTextProps> = (props) => (
  <LoadingContainer {...props}>
    <LoadingH1Skeleton />
  </LoadingContainer>
);

export const ToolbarTitle = styled.h6`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeigth.header};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.lg};
  text-align: center;
  position: absolute;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 0;
  right: 0;
  width: 40%;
  max-width: 25rem;
`;

export const GameInfoTitle = styled.h6`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeigth.header};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.xl};
  text-transform: lowercase;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const RankTitle = styled.span`
  font-family: ${(props) => props.theme.fontFamily.header};
  font-weight: ${(props) => props.theme.fontWeigth.header};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.base};
  margin-right: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5rem;
`;

export const GameInfoSubheader = styled.span`
  font-family: ${(props) => props.theme.fontFamily.system};
  letter-spacing: ${(props) => props.theme.letterSpacing.header};
  font-size: ${(props) => props.theme.fontSize.base};
  margin: 0.5rem 0;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;
