import { PAGE_NAME } from ".";
import { getTracksName } from "../../components/card/utils";
import { Bet } from "../../types/bet";
import { Game } from "../../types/game/game";
import { formatDate } from "../date-util";

export const getTravPageTitle = (bet?: Bet, game?: Game): string =>
  getPageTitle(
    bet && game
      ? `${bet.name ? `${bet.name} - ` : ""}${bet.gameType}: ${getTracksName(
          game.tracks
        )}, ${formatDate(bet.date)}`
      : undefined
  );

export const getPageTitle = (detail?: string, inverseOrder?: boolean): string =>
  detail
    ? inverseOrder
      ? `${PAGE_NAME} - ${detail}`
      : `${detail} - ${PAGE_NAME}`
    : PAGE_NAME;
