import retry from "fetch-retry";
import { useCallback, useEffect, useState } from "react";
import { useNetworkStatus } from "react-adaptive-hooks";
import { track } from "../analytics";

const fetchRetry = retry(fetch, {
  retries: 8,
  retryDelay: (attempt: number) => Math.pow(2, attempt) * 1000,
});

function getOnlineStatus() {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
}

export function useOnlineStatus(): boolean {
  const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());
  const { effectiveConnectionType } = useNetworkStatus();

  const pingEndpoint = "/api/ping";

  const testConnection = useCallback(
    () =>
      fetchRetry(pingEndpoint)
        .then(() => {
          setOnlineStatus(true);
          track("Network Changed", {
            online_status: true,
            connection_type: effectiveConnectionType,
          });
        })
        .catch(() => {
          setOnlineStatus(false);
          track("Network Changed", {
            online_status: false,
            connection_type: effectiveConnectionType,
          });
        }),
    [setOnlineStatus]
  );

  const handleConnectionChange = useCallback(() => {
    const onlineStatus = getOnlineStatus();

    if (onlineStatus) testConnection();
    else setOnlineStatus(false);

    track("Network Changed", {
      online_status: onlineStatus,
      connection_type: effectiveConnectionType,
    });
  }, [setOnlineStatus, getOnlineStatus]);

  useEffect(() => {
    handleConnectionChange();
  }, [effectiveConnectionType]);

  useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  return onlineStatus;
}
