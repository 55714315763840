import create from "zustand";
import { ZustandCreate } from "./types";

type GameData = {
  gameType?: string;
  amountOfRaces?: number;
  rowCost?: number;
};

interface GameStore extends GameData, ZustandCreate {
  setGameData: (gameData: GameData) => void;
  resetGameData: () => void;
}

const initGameStore: GameData = {
  gameType: undefined,
  amountOfRaces: undefined,
  rowCost: undefined,
};

const useGameStore = create<GameStore>((set) => ({
  ...initGameStore,
  setGameData: (gameData) => set(gameData),
  resetGameData: () => set(initGameStore),
}));

export default useGameStore;
