import * as React from "react";

interface UseScrollTriggerOptions {
  disableHysteresis?: boolean;
  threshold?: number;
}

const defaultTarget = typeof window !== "undefined" ? window : null;

function defaultTrigger(
  store: React.MutableRefObject<any>,
  options: UseScrollTriggerOptions
) {
  const { disableHysteresis = false, threshold = 100 } = options;
  const previous = store.current;

  if (defaultTarget) {
    // Get vertical scroll
    store.current =
      defaultTarget.pageYOffset !== undefined ? defaultTarget.pageYOffset : 0;
  }

  if (!disableHysteresis && previous !== undefined) {
    if (store.current < previous) {
      return false;
    }
  }

  return store.current > threshold;
}

export default function useScrollTrigger(
  options: UseScrollTriggerOptions = {}
): boolean {
  const store = React.useRef();
  const [trigger, setTrigger] = React.useState(() =>
    defaultTrigger(store, options)
  );

  React.useEffect(() => {
    const handleScroll = () => {
      setTrigger(defaultTrigger(store, options));
    };

    handleScroll(); // Re-evaluate trigger when dependencies change
    defaultTarget?.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      defaultTarget?.removeEventListener("scroll", handleScroll);
    };
    // See Option 3. https://github.com/facebook/react/issues/14476#issuecomment-471199055
  }, [defaultTarget, JSON.stringify(options)]);

  return trigger;
}
