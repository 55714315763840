import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import isServer from "../../../libs/is-server";
import { Bet, BetRow } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import useAppStore, { ReductionRequirementView } from "../../app-store";
import uploadBet from "../upload-bet";

export const addPointsReduction = async (
  shouldUpdateReductionView: boolean,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  const { currentBetId } = useAppStore.getState();

  if (!currentBetId || !bet) {
    return;
  }

  const { reductionRequirement } = bet;

  const addPointsReductionToRow = (row: BetRow): BetRow => {
    return {
      ...row,
      pointsReductions: [
        ...(row.pointsReductions || []),
        row.order.map((_, i) => i + 1),
      ],
    };
  };

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      pointsReductions: [
        ...(reductionRequirement.pointsReductions || []),
        {
          legNumbers: bet.rows.map((row) => row.legNr),
          minPoints: null,
          maxPoints: null,
        },
      ],
    },
    rows: bet.rows.map(addPointsReductionToRow),
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (shouldUpdateReductionView) {
    useAppStore
      .getState()
      .setReductionRequirementView(
        ReductionRequirementView.POINTS,
        (newBet.reductionRequirement.pointsReductions?.length || 1) - 1
      );
  }

  if (!isServer) {
    uploadBet(
      currentBetId,
      newBet,
      () => set({ uploading: false }),
      idbBetSyncWorkerApi
    );

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(currentBetId, newBet);
  }
};
