import styled from "@emotion/styled";
import Avatar from "@material-ui/core/Avatar";
import AccountRoundIcon from "@material-ui/icons/AccountCircle";
import create from "zustand";
import { Action } from "../components/bottom-navigation";
import navs from "../routes/navs";
import { ZustandCreate } from "./types";

export enum ReductionRequirementView {
  RANK,
  BASE_HORSE,
  ABC,
  POINTS,
  FACTOR,
}

const IconAvatar = styled(Avatar)`
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.125rem;
`;

type AppState = {
  isOnline: boolean;
  reductionRequirementView: ReductionRequirementView;
  navs: Action[];
  appBarTitle: string;
  selectedReductionIndex?: number;
  currentBetId?: string;
  settingsIsMounted: boolean;
};

interface AppStore extends AppState, ZustandCreate {
  setUserNav: (label: string, photoUrl?: string) => void;
  setAppBarTitle: (appBarTitle: string) => void;
  setOnlineStatus: (isOnline: boolean) => void;
  setReductionRequirementView: (
    reductionRequirementView: ReductionRequirementView,
    selectedReductionIndex?: number
  ) => void;
  resetReductionRequirementView: () => void;
  setCurrentBetId: (currentBetId: string) => void;
  resetCurrentBetId: () => void;
  setSettingsIsMounted: (isMounted: boolean) => void;
}

const useAppStore = create<AppStore>((set, get) => ({
  navs,
  appBarTitle: "Reducera",
  isOnline: true,
  reductionRequirementView: ReductionRequirementView.RANK,
  selectedReductionIndex: undefined,
  currentBetId: undefined,
  settingsIsMounted: false,
  setUserNav: (label, photoUrl) => {
    const { navs: oldNavs } = get();
    const userNav = oldNavs.pop();
    set({
      navs: [
        ...oldNavs,
        {
          ...userNav,
          icon: photoUrl ? (
            <IconAvatar src={photoUrl} alt="Profilbild" />
          ) : (
            <AccountRoundIcon />
          ),
          label,
        } as Action,
      ],
    });
  },
  setAppBarTitle: (appBarTitle) => set({ appBarTitle }),
  setOnlineStatus: (isOnline) => set({ isOnline }),
  setReductionRequirementView: (
    reductionRequirementView,
    selectedReductionIndex
  ) => set({ reductionRequirementView, selectedReductionIndex }),
  resetReductionRequirementView: () =>
    set({
      reductionRequirementView: ReductionRequirementView.RANK,
      selectedReductionIndex: undefined,
    }),
  setCurrentBetId: (currentBetId) => set({ currentBetId }),
  resetCurrentBetId: () => set({ currentBetId: undefined }),
  setSettingsIsMounted: (settingsIsMounted) => set({ settingsIsMounted }),
}));

export default useAppStore;
