import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { BASE_URL, getPageTitle } from "../../libs/seo";

interface Props {
  title?: string;
  description?: string;
  noIndex?: boolean;
}

const SEO: React.FC<Props> = ({
  title,
  description = "Reducera är den nya generationens verktyg för reducering på trav. Spela reducerade travsystem på t.ex V75 och V86, helt gratis!",
  noIndex,
  children,
}) => {
  const router = useRouter();

  const actualTitle = title || getPageTitle();
  const ogImage = `${BASE_URL}/images/og-reducera.png`;
  const canonicalUrl = `${BASE_URL}${router.asPath}`;

  return (
    <Head>
      {noIndex ? (
        <meta name="robots" content="noindex, nofollow" key="robots" />
      ) : (
        <meta name="robots" content="index, follow" key="robots" />
      )}
      <title key="title">{actualTitle}</title>
      <meta name="description" content={description} key="description" />
      <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta property="og:locale" content="sv_SE" key="og_locale" />
      <meta property="og:type" content="website" key="og_type" />
      <meta property="og:title" content={actualTitle} key="og_title" />
      <meta
        property="og:description"
        content={description}
        key="og_description"
      />
      <meta property="og:image" content={ogImage} key="og_image" />
      <meta property="og:image:width" content="1200" key="og_image_width" />
      <meta property="og:image:height" content="630" key="og_image_height" />
      <meta property="og:url" content={canonicalUrl} key="og_url" />
      <meta property="og:site_name" content="Reducera" key="og_site_name" />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter_card"
      />
      <meta name="twitter:title" content={actualTitle} key="twitter_title" />
      <meta
        name="twitter:description"
        content={description}
        key="twitter_description"
      />
      <meta name="twitter:image" content={ogImage} key="twitter_image" />
      {children}
    </Head>
  );
};

export default SEO;
