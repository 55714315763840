import * as Sentry from "@sentry/nextjs";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import getConfig from "next/config";
import firebaseApp from "../firebase/app";

const config = getConfig();
const { isProd, appVersion = "unknown" } = config?.publicRuntimeConfig;
const appVersionProps = { app_version: appVersion };

const snakeCase = (string: string) => {
  return string
    .replace(/\d+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
};

export const track = (
  event: string,
  properties?: Record<string, unknown>
): void => {
  if (isProd) {
    const analytics = getAnalytics(firebaseApp);
    const props = { ...properties, ...appVersionProps };
    logEvent(analytics, snakeCase(event), props);
  } else {
    // eslint-disable-next-line no-console
    console.log("Track:", event, properties || "");
  }
};

export const page = (url: string): void => {
  if (isProd) {
    const analytics = getAnalytics(firebaseApp);
    logEvent(analytics, "page_view", {
      path_name: url,
      ...appVersionProps,
    });
  } else {
    // eslint-disable-next-line no-console
    console.log("Page:", url);
  }
};

export const identify = (userId?: string, email?: string): void => {
  if (isProd) {
    const analytics = getAnalytics(firebaseApp);
    if (userId) {
      setUserId(analytics, userId);
      setUserProperties(analytics, { ...appVersionProps });
      Sentry.setUser({ id: userId, email });
    }
  } else {
    // eslint-disable-next-line no-console
    console.log("Identify:", userId, { email, ...appVersionProps });
  }
};

export const resetUser = (): void => {
  if (isProd) {
    const analytics = getAnalytics(firebaseApp);
    // @ts-ignore
    setUserId(analytics, null);
    Sentry.configureScope((scope) => scope.setUser(null));
  } else {
    // eslint-disable-next-line no-console
    console.log("Reset user");
  }
};
