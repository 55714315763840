import styled from "@emotion/styled";
import NoSsr from "@material-ui/core/NoSsr";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo } from "react";
import shallow from "zustand/shallow";
import { Action } from "../components/bottom-navigation";
import IdTokenSubscriber from "../components/id-token-subscriber";
import RouteChangeTracker from "../components/route-change-tracker";
import areWorkersSupported from "../libs/caniuse-web-workers";
import { useBetUploader } from "../libs/hooks/use-bet-uploader";
import { useOnlineStatus } from "../libs/hooks/use-online-status";
import isServer from "../libs/is-server";
import useAppStore from "../state/app-store";
import useBetStore from "../state/bet-store";
import useSnackbarStore from "../state/snackbar-store";
import type { CustomWindow } from "../types/window";
import AppBarContainer from "./app-bar";
import BottomNavigationContainer from "./bottom-navigation";

const SnackbarHandler = dynamic(() => import("./snackbar-handler"), {
  ssr: false,
});

declare const window: CustomWindow;

const viewBottomNavigation = (navs: Action[], currentPathname: string) =>
  navs.map(({ pathname }) => pathname).includes(currentPathname);

// TODO: Add existing games
const shouldUseTabs = (currentPathname: string) =>
  currentPathname === "/trav/[id]";

interface MainProps {
  usingBottomNavigation: boolean;
}

const Main = styled("main")<MainProps>`
  margin: 0 auto;
  ${(props) =>
    props.usingBottomNavigation
      ? "padding-bottom: calc(4.5rem + env(safe-area-inset-bottom))"
      : ""}
`;

const Layout: React.FC = ({ children }) => {
  const router = useRouter();

  const pushSnackbar = useSnackbarStore((state) => state.pushSnackbar, shallow);

  const [setOnlineStatus, navs] = useAppStore(
    (state) => [state.setOnlineStatus, state.navs],
    shallow
  );

  const isOnline = useOnlineStatus();

  useEffect(() => {
    setOnlineStatus(isOnline);
  }, [isOnline]);

  if (!isServer) useBetUploader(isOnline);

  const uploading = useBetStore((state) => state.uploading);

  const usingBottomNavigation = viewBottomNavigation(navs, router.pathname);

  const beforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (uploading) {
        e.preventDefault();
        e.returnValue = true;
      }
    },
    [uploading]
  );

  useEffect(() => {
    const ua = navigator.userAgent;
    const workerSupport = areWorkersSupported();
    const isIE = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    if (isIE || !workerSupport) {
      pushSnackbar({
        message: "Din webbläsare stöds inte.",
        action: {
          text: "Skaffa Chrome",
          callback: () => {
            router.push("https://www.google.com/intl/sv/chrome/");
          },
        },
        isCloseable: true,
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnload);

    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, [uploading]);

  const useTabs = useMemo(() => shouldUseTabs(router.pathname), [
    router.pathname,
  ]);

  return (
    <>
      <AppBarContainer
        shouldUseTabs={useTabs}
        shouldUseBackButton={!usingBottomNavigation}
        shouldUseSettingsButton={useTabs}
      />
      <Main usingBottomNavigation={usingBottomNavigation} key={router.route}>
        {children}
      </Main>
      {usingBottomNavigation && (
        <BottomNavigationContainer actions={navs} pathname={router.pathname} />
      )}
      <NoSsr>
        <IdTokenSubscriber />
        <RouteChangeTracker />
      </NoSsr>
      <SnackbarHandler usingBottomNavigation={usingBottomNavigation} />
    </>
  );
};

export default Layout;
