import dayjs from "dayjs";
import "dayjs/locale/sv";

dayjs.locale("sv");

export const formatDay = (date: dayjs.ConfigType): string => {
  const day = dayjs(date);
  if (day.isSame(dayjs(), "date")) {
    return "idag";
  } else if (day.isSame(dayjs().add(1, "day"), "date")) {
    return "imorgon";
  }
  return day.format("dddd (D/M)");
};

export const formatTime = (date: dayjs.ConfigType): string => {
  return dayjs(date).format("HH:mm");
};

export const formatDate = (date: dayjs.ConfigType): string => {
  const day = dayjs(date);
  if (day.isSame(dayjs(), "date")) {
    return `idag ${day.format("HH:mm")}`;
  } else if (day.isSame(dayjs().add(1, "day"), "date")) {
    return `imorgon ${day.format("HH:mm")}`;
  }
  return day.format("dddd HH:mm (D/M)");
};

export const getXmlDateString = (date?: dayjs.ConfigType): string => {
  const now = dayjs(date);
  return now.format("YYYY-MM-DD");
};

export const getXmlTimeString = (date?: dayjs.ConfigType): string => {
  const now = dayjs(date);
  return now.format("HH:mm:ss");
};

export const getCurrentTimeStamp = (): number => dayjs().unix();
