import styled from "@emotion/styled";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useEffect, useMemo } from "react";

export interface TabsProps {
  indicatorBackground: string;
}

export interface AppBarTabsProps extends TabsProps {
  amountOfRaces: number;
  tabValue: number;
  onTabChange?: (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: any
  ) => void;
  onUnmount?: () => any;
}

const StyledTab = styled(Tab)`
  & .MuiTab-wrapper {
    font-family: ${(props) => props.theme.fontFamily.system};
  }
`;

const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== "indicatorBackground",
})<TabsProps>`
  justify-content: center;
  background: ${(props) => props.theme.color.white};

  & .MuiTabs-scroller {
    flex-grow: 0;
  }

  & .MuiTabs-indicator {
    background: ${(props) => props.indicatorBackground};
  }
`;

const AppBarTabs: React.FC<AppBarTabsProps> = React.memo(
  ({
    amountOfRaces,
    tabValue,
    onTabChange,
    onUnmount,
    indicatorBackground,
  }) => {
    const tabs = useMemo(() => {
      if (!amountOfRaces) return null;

      const raceNrs = Array(amountOfRaces)
        .fill(undefined)
        .map((_, i) => i + 1);

      // If !gameType => show skeleton tabs
      return raceNrs.map((raceNr) => (
        <StyledTab key={`bet-tab-${raceNr}`} label={`Avd. ${raceNr}`} />
      ));
    }, [amountOfRaces]);

    useEffect(() => {
      return () => {
        if (onUnmount) {
          onUnmount();
        }
      };
    }, []);

    return (
      tabs && (
        <StyledTabs
          value={tabValue}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorBackground={indicatorBackground}
        >
          {tabs}
        </StyledTabs>
      )
    );
  }
);

export default AppBarTabs;
