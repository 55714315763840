import AccountRoundIcon from "@material-ui/icons/AccountCircle";
import EventIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import { Action } from "../components/bottom-navigation";

const navs: Action[] = [
  { pathname: "/", label: "Hem", key: "home", icon: <HomeIcon /> },
  {
    pathname: "/calendar",
    label: "Kalender",
    key: "calendar",
    icon: <EventIcon />,
  },
  {
    pathname: "/user",
    label: "Profil",
    key: "user",
    icon: <AccountRoundIcon />,
  },
];

export default navs;
