import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import isServer from "../../../libs/is-server";
import { Bet, Reduction } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import uploadBet from "../upload-bet";

export const updateFactorReduction = async (
  id: string,
  newFactorReduction: Partial<Reduction>,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  if (!bet) {
    return;
  }

  const { reductionRequirement } = bet;

  if (reductionRequirement.factorReduction == null) {
    reductionRequirement.factorReduction = {};
  }

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      factorReduction: {
        ...reductionRequirement.factorReduction,
        ...newFactorReduction,
      },
    },
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (!isServer) {
    uploadBet(id, newBet, () => set({ uploading: false }), idbBetSyncWorkerApi);

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(id, newBet);
  }
};
