import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import useAppStore from "../../state/app-store";
import useUserStore from "../../state/user-store";
import { ID_TOKEN_KEY } from "../../types/user";
import { identify, resetUser } from "../analytics";
import firebaseApp from "../firebase/app";
import parseName from "../parse-name";
import { reportError } from "../sentry/logger";

export function useIdTokenSubscriber(): void {
  const [setUser, setLoading] = useUserStore((state) => [
    state.setUser,
    state.setLoading,
  ]);
  const setUserNav = useAppStore((state) => state.setUserNav);
  const auth = getAuth(firebaseApp);

  useEffect(() => {
    setLoading(true);

    const onIdTokenChangedUnsub = auth.onIdTokenChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          sessionStorage.setItem(ID_TOKEN_KEY, token);
        });
      } else {
        sessionStorage.removeItem(ID_TOKEN_KEY);
      }
    });

    const onAuthStateChangedUnsub = auth.onAuthStateChanged(
      (user) => {
        setUser(user || undefined);
        setUserNav(
          user?.displayName ? parseName(user.displayName).firstName : "Profil",
          user?.photoURL || undefined
        );
        setLoading(false);
        if (user) {
          // Sign in
          identify(user.uid, user.email || undefined);
        } else {
          // Logout
          resetUser();
        }
      },
      (error) => {
        reportError(new Error("onAuthStateChangedUnsub-error"), {
          firebaseError: error,
        });
        setUser(undefined);
        setUserNav("Profil", undefined);
        setLoading(false);
        resetUser();
      }
    );
    return () => {
      onIdTokenChangedUnsub();
      onAuthStateChangedUnsub();
    };
  }, []);
}
