import create from "zustand";
import { SnackbarMessage } from "../types/snackbar-message";
import { ZustandCreate } from "./types";

type SnackbarState = {
  queue: SnackbarMessage[];
  isOpen: boolean;
};

interface SnackbarStore extends SnackbarState, ZustandCreate {
  pushSnackbar: (snackbarMessage: SnackbarMessage) => void;
  popSnackbar: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

const useSnackbarStore = create<SnackbarStore>((set) => ({
  queue: [],
  isOpen: false,
  pushSnackbar: (snackbarMessage) =>
    set((state) => ({
      queue: [
        ...state.queue,
        {
          ...snackbarMessage,
          key: snackbarMessage.key || new Date().getTime(),
        },
      ],
    })),
  popSnackbar: () => set((state) => ({ queue: state.queue.slice(1) })),
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export default useSnackbarStore;
