import React, { useMemo } from "react";
import BottomNavigation, { Action } from "../../components/bottom-navigation";

interface Props {
  pathname: string;
  actions: Action[];
}

const cutPathname = (pathname: string): string =>
  pathname.split("/").length > 3
    ? pathname.split("/").slice(0, 2).join("/")
    : pathname;

const BottomNavigationContainer: React.FC<Props> = React.memo(
  ({ pathname, actions }) => {
    const value = useMemo(() => cutPathname(pathname), [pathname]);
    return <BottomNavigation actions={actions} pathname={value} />;
  }
);

export default BottomNavigationContainer;
