import styled from "@emotion/styled";
import MuiBottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import React from "react";
import { track } from "../../libs/analytics";
import { LinkProps } from "../../types/link-props";
import MuiLink from "../mui-link";
import { Action } from "./types";

interface Props {
  actions: Action[];
  pathname: string;
}

const StyledBottomNavigation = styled(MuiBottomNavigation)`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(56px + env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);

  & .Mui-selected {
    color: ${(props) => props.theme.color.black};
  }
`;

interface BottomNavigationActionProps extends LinkProps {
  component?: any;
}

const StyledBottomNavigationAction = styled(BottomNavigationAction)<
  BottomNavigationActionProps
>`
  color: ${(props) => props.theme.color.background.grey.default};
  :hover {
    text-decoration: none;
  }
  & .MuiBottomNavigationAction-label {
    font-family: ${(props) => props.theme.fontFamily.system};
  }
`;

const BottomNavigation: React.FC<Props> = React.memo(
  ({ actions, pathname }) => (
    <StyledBottomNavigation
      showLabels
      className="MuiPaper-elevation3"
      value={pathname}
    >
      {actions.map((action, i) => (
        <StyledBottomNavigationAction
          key={`${i}-${action.key}`}
          className={action.pathname === pathname ? "Mui-selected" : ""}
          label={action.label}
          icon={action.icon}
          value={action.pathname}
          showLabel
          component={MuiLink}
          href={action.pathname}
          onClick={() =>
            track("Bottom Navigation Action Clicked", {
              pathname: action.pathname,
              label: action.label,
            })
          }
          replace
        />
      ))}
    </StyledBottomNavigation>
  )
);

export * from "./types";
export default BottomNavigation;
