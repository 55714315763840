export class FetchError extends Error {
  public errorCode: number;
  public errorMessage: string | undefined;

  constructor(code: number, message?: string) {
    super(`${code}: ${message}`);

    // Object.setPrototypeOf(this, FetchError.prototype);

    this.errorCode = code;
    this.errorMessage = message;
  }
}

export const isFetchError = (data: any): data is FetchError =>
  (data as FetchError).errorCode !== undefined;
