import { Remote } from "comlink";
import { GetState, SetState } from "zustand";
import { BetStore } from "..";
import isServer from "../../../libs/is-server";
import { replaceInList } from "../../../libs/list-utils/replace-in-list";
import { AbcRow, AbcVariant, Bet } from "../../../types/bet";
import { IDBBetHandler } from "../../../workers/idb-bet-storer";
import { IDBBetSyncHandler } from "../../../workers/idb-bet-sync-handler";
import uploadBet from "../upload-bet";

export const updateAbcRow = async (
  id: string,
  abcVariantIndex: number,
  abcRowIndex: number,
  newAbcRow: Partial<AbcRow>,
  set: SetState<BetStore>,
  get: GetState<BetStore>,
  idbBetWorkerApi?: Remote<IDBBetHandler>,
  idbBetSyncWorkerApi?: Remote<IDBBetSyncHandler>
): Promise<void> => {
  const { bet } = get();

  if (!bet) {
    return;
  }

  const { reductionRequirement } = bet;

  if (reductionRequirement.abcReduction == null) {
    throw new Error("No abcReduction when updateAbcRow");
  }

  const { abcReduction } = reductionRequirement;

  const { abcRows } = abcReduction.abcVariants.slice()[abcVariantIndex];

  const newBet: Bet = {
    ...bet,
    reductionRequirement: {
      ...reductionRequirement,
      abcReduction: {
        abcVariants: replaceInList<AbcVariant>(
          reductionRequirement.abcReduction.abcVariants,
          abcVariantIndex,
          {
            ...reductionRequirement.abcReduction.abcVariants[abcVariantIndex],
            ...{
              abcRows: replaceInList<AbcRow>(abcRows, abcRowIndex, {
                ...abcRows[abcRowIndex],
                ...newAbcRow,
              }),
            },
          }
        ),
      },
    },
  };

  set({
    bet: newBet,
    uploading: true,
  });

  if (!isServer) {
    uploadBet(id, newBet, () => set({ uploading: false }), idbBetSyncWorkerApi);

    if (idbBetWorkerApi) idbBetWorkerApi.storeBet(id, newBet);
  }
};
